<template>
  <el-dialog title="查看" :close-on-click-modal="false" :visible.sync="visible">

    <div class="box">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
        <table class="detailTable_search" style="width: 100%">
          <tr>
            <td class="titleTd width80px">区域</td>
            <td class="valueTd">
              <el-select v-model="dataForm.regionId" filterable clearable style="width: 100%">
                <el-option v-for="item in selfAreaCodeList" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <div class="wxts_msg_search">
          <div class="f_t">
            <el-button-group :disabled="dataListLoading">
              <el-button @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
              <!-- <el-button v-if="isAuth('dataflow:olxareaauthmain:delete')" type="danger" @click="deleteHandle()"
                                       :disabled="dataListSelections.length <= 0">批量删除
                            </el-button> -->
            </el-button-group>
          </div>
        </div>
      </el-form>
      <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
        style="width: 100%;">
        <el-table-column prop="orkName" header-align="center" align="center" :show-overflow-tooltip="true" label="指标名称">
        </el-table-column>
        <el-table-column prop="orkState" header-align="center" align="center" label="状态">
          <template slot-scope="scope">
            <sysDict type="management_index_state" :value="scope.row.orkState"></sysDict>
          </template>
        </el-table-column>
        <el-table-column prop="orkYear" header-align="center" align="center" label="指标年份">
        </el-table-column>
        <el-table-column prop="userName" header-align="center" align="center" label="填写人">
        </el-table-column>
        <el-table-column prop="areaCode" header-align="center" align="center" label="所属区域">
          <template slot-scope="scope">
            <org :value="scope.row.areaCode" />
          </template>
        </el-table-column>
        <el-table-column prop="transDate" header-align="center" align="center" label="下发时间">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作" >
          <template slot-scope="scope">
            <el-button type="text" size="small" class="del-button" @click="deleteHandle(scope.row)" v-if="scope.row.orkState!='03'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
</el-dialog>
</template>
<script>


import { getViewList, deleteData } from '@/api/dataflow/olxpokrmanagemain.js'
import { selectAllCode } from '@/api/dataflow/olxareaauthmain.js'
export default {
  data() {
    return {
      visible: false,
      issudeVisible: false,
      selfAreaCodeList: [],
      dataForm: {
        id: '',
        regionId: '',
        orkName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
    }
  },
  components: {
  },
  activated() {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }
  },
  computed: {
    regionCode: {
      get() {
        const newregionId = this.$store.state.user.regionCode;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return newgridId;
        } else {
          return ''
        }
      }
    }
  },
  methods: {
    init(id) {
      this.visible = true
      this.dataForm.id = id
      const code = '1000'
      this.getSelfAreaCode(code)
      this.getDataList()
    },
    refresh() {
      // this.$router.push({name:'dataflow_olxpokrmanagemain',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      const params_ = {
        'id': this.dataForm.id,
        'orkName': this.dataForm.orkName,
        'areaCode': this.dataForm.regionId,
        'page': this.pageIndex,
        'limit': this.pageSize,
      }
      getViewList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("getList=>" + JSON.stringify(data))
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
          if (data.data.page !== this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          this.dataList = []
          this.totalCount = 0
          this.pageIndex = 1
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    //所属区域
    getSelfAreaCode(code) {
      selectAllCode(code).then(({ data }) => {
        this.selfAreaCodeList = data.data
      })
    },
    // 删除
    deleteHandle(row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})
      // console.log(ids)
      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({ data }) => {

          console.log(JSON.stringify(data))

          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            
            const totalPage = Math.ceil((this.totalCount - 1) / this.pageSize);
            // 总页数
            this.pageIndex = this.pageIndex > totalPage ? totalPage : this.pageIndex;
            this.pageIndex = this.pageIndex < 1 ? 1 : this.pageIndex;

            this.getDataList()
            this.$emit('refreshDataList', true)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    }
  }
}
</script>